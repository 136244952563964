<template>
	<!-- 授信申请添加 -->
	<div :class="themeClass" class="contBox margin-t-10 bg-fff">
		<div class="newPersonbtn-Boxx">
			<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13 padding-l-13">
				授信申请
			</div>
		</div>
		<div class="newPersonbtn-Box">
			<el-form :model="form" :rules="rules" ref="form" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">
					<span class="iconfont icon-biaodan xinzengIcon color-theme"></span>
					<span> 授信申请信息&nbsp;&nbsp;</span>
				</div>
				<!-- 		<el-form-item label="店铺名称" prop="fShopName">
					<el-input v-model="form.fShopName" placeholder="请输入店铺名称" disabled></el-input>
				</el-form-item> -->
				<el-form-item label="申请单号" prop="fBillNumber">
					<el-input v-model="form.fBillNumber" disabled placeholder="请输入申请单号"></el-input>
				</el-form-item>

				<!--<el-form-item label="已使用额度" prop="fTaxAccounts">
				  <el-input
					v-model="form.fTaxAccounts"
					placeholder="请输入已使用额度"
				  ></el-input>
				</el-form-item> -->
				<el-form-item label="最高额度" prop="fCreditLimitsMoney">
					<el-input class="prefix-input" v-model="form.fCreditLimitsMoney" maxlength='11' placeholder="请输入最高额度" @input="setOnlyMoney">
						<span slot="prefix" class="font-color-FF0000">￥</span>
					</el-input>
				</el-form-item>
				<el-form-item label="还款日期" prop="fRepaymentingDate">
					<el-date-picker style="width:100%" v-model="form.fRepaymentingDate" type="date" value-format="yyyy-MM-dd"
					 placeholder="选择还款日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="form.fRemark" placeholder="请输入备注"></el-input>
				</el-form-item>
				<!--<el-form-item v-for="(item, index) of uploadRule" :key="index" :prop="item.rule.fIsNecessary == 0 ? '' : 'annexEntityArray'">
					<div slot="label" class="flex-row-end-center">
						<div class="color-red margin-r-5">*</div>
						{{item.rule.fRuleName}}
					</div>
					<div class="padding-15 border-F2F2F2">
						<UploadImgList :accept="limitTypeArr" :rules="item.rule" :uploadToken="uploadToken[index]" :limit="1" v-model="annexEntityArray[index]"
						 uploadType="image" />
					</div>
					<div class="font-size13 message-text">
						* 提示：请下载本商城提供的授信申请模板并加盖公章上传
					</div>
				</el-form-item> -->
				<el-form-item>
					<el-button class="background-color-theme" @click="submitForm('form')">保存</el-button>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import UploadImgList from "@/components/upload/UploadImgList";
	export default {
		name: "CreditLineAdd",
		props: {
			value: Array,
		},
		data() {
			const rulesTelephone = (rule, value, callback) => {
				if (!/^1[34578]\d{9}$/.test(value)) {
					callback("手机号不正确");
				} else {
					callback();
				}
			};
			var amount = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入最高额度'));
				} else {
					let amountRul = /(^(([1-9]([0-9]+)?)|(0{1}))(\.[0-9]{1,2})?$)/;
					if (amountRul.test(value)) {
						if (value == 0) {
							callback(new Error('请输入最高额度不能为0'));
						} else if (value > 99999999.99) {
							callback(new Error('最高额度不能大于99,999,999.99.99'));
						} else {
							callback();
						}
					} else {
						callback(new Error('最高额度为数字且最多有两位小数'));
					}
				}
			};
			return {
				uploadRule: [],
				annexEntityArray: [],
				limitTypeArr: [],
				uploadToken: [],
				form: {
					fShopName: "",
					fBillNumber: "", //单号
					fShopUnitID: "",
					fCreditLimitsMoney: "", //最高额度
					fRepaymentingDate: "", //还款日期
					fModelAnnexPath: "", //附件模板路径
					fAnnexPath: "", //附件路径
					fAppTypeID: 2,
					fRemark: " ",
				},
				editAnnexes: [],
				rules: {
					// fShopName: [{
					// 	required: true,
					// 	message: "店铺名称不能为空",
					// 	trigger: "blur",
					// }, ],
					fBillNumber: [{
						required: true,
						message: "请输入申请单号",
						trigger: "blur",
					}, ],
					fCreditLimitsMoney: [{
						required: true,
						message: "请输入最高额度",
						trigger: "blur",
					},{
						required: true,
						validator: amount,
						trigger: 'blur'
					} ],
					fRepaymentingDate: [{
						required: true,
						message: "请选择还款日期",
						trigger: "blur",
					}, ],
					fRemark: [{
						required: true,
						message: "请输入备注",
						trigger: "blur",
					}],
					// annexEntityArray: [{
					// 	validator: (rule, value, callback) => {
					// 		if (this.annexEntityArray.length < 1) {
					// 			callback(new Error("附件信息为必填项"));
					// 		} else {
					// 			callback();
					// 		}
					// 	},
					// }, ]
				},
				oldfIsNecessary: [],
				nowfIsNecessary: [],
				fIsNecessaryData: [],
				pictureIsNecessaryDataName: [],
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		components: {
			UploadImgList,
		},
		mounted() {
			this.getinitNum();
			this.getinitAnnexRules();
			this.form.fShopUnitID = this.$route.query.id;
			this.getShopCreditlist(this.$route.query.id); //   获取店铺ID对应的信息
		},
		methods: {
			//初始化获取店铺对应的授信信息
			getShopCreditlist(id) {

			},
			//获取单号
			getinitNum() {
				this.ApiRequestPost(
					"api/mall/ebsale/credit-limits-requisition/init", {}
				).then(
					(result) => {
						this.form.fBillNumber = result.obj;
					},
					(rej) => {}
				);
			},
			//获取附件规则
			getinitAnnexRules() {
				this.ApiRequestPost(
					"api/mall/ebsale/credit-limits-requisition/get-create-credit-type-rule"
				).then(
					(res) => {
						this.uploadRule = res.obj;
						console.log("获取附件验证规则", this.uploadRule);
						let Token = {};
						let Type = [];
						for (let item of res.obj) {
							if (item.rule.fIsNecessary == 1) {
								this.fIsNecessaryData.push(item.rule.fAnnexTypeID);
								this.pictureIsNecessaryDataName.push(item.rule.fRuleName);
							}
							Type = item.rule.fFileType.split(",");
							Token.token = item.token;
							this.uploadToken.push(Token);
						}
						for (let i = 0; i < Type.length; i++) {
							Type[i] = "." + Type[i];
						}
						this.limitTypeArr = Type.toString();
					},
					(error) => {}
				);
			},
			//保存
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// for (let item of this.annexEntityArray) {
						// 	this.form.fAnnexPath = item[0].fPath
						// }
						this.ApiRequestPost(
							"api/mall/ebsale/credit-limits-requisition/create",
							this.form
						).then(
							(res) => {
								this.$router.push("/businessme/CreditLine")
							},
							(error) => {}
						);
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			setOnlyMoney(){
				this.$nextTick(() => {
					let val = this.form.fCreditLimitsMoney.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
					this.form.fCreditLimitsMoney = val;
				});
			}

		},
	};
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.xinzengIcon {
		font-size: 26px;
		position: relative;
		left: 4px;
		top: 3px;
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.PersonanProfile-Number {
		font-size: 12px;
		color: #0173fe;
	}

	.aaaa {
		width: 331px;
		height: 117px;
		border-radius: 4px;
		border: 1px solid rgba(228, 228, 228, 1);
		padding: 10px 0px 0px 10px;
	}

	.contBox {
		width: 100%;
	}

	.demo-ruleForm {
		width: 470px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-title1 {
		width: 110px;
		height: 30px;
		background-color: #0173fe;
		border-radius: 2px;
		color: #ffffff;
		line-height: 30px;
		text-align: center;
		// float:right;
	}

	.newPersonbtn-Boxx {
		display: flex;
		justify-content: space-between;
		margin-right: 20px;
	}

	.newPersonbtn-Box {
		border: 1px solid #f2f2f2;
		padding-top: 20px;
		min-height: 700px;
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		color: #000000;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-cascader {
		width: 100%;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	::v-deep .el-upload--picture-card {
		width: 97px;
		height: 97px;
		line-height: 97px;
		margin: 6px;
	}

	::v-deep .el-upload-list--picture-card .el-upload-list__item {
		width: 97px;
		height: 97px;
		line-height: 97px;
		margin: 6px;
	}

	.color-red {
		color: #ff0000;
	}

	.message-text {
		color: #FF9900;
	}

	.prefix-input::v-deep {
		.el-input__inner {
			padding-left: 20px;
		}
	}
</style>
